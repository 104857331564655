import React from 'react';
import Layout from '../components/layout';
import { Link } from 'gatsby';

const notFound = () => (
  <Layout>
    <div className="min-w-screen min-h-screen bg-blue-100 flex items-center p-5 lg:p-20 overflow-hidden relative">
      <div className="flex-1 min-h-full min-w-full rounded-3xl bg-white shadow-xl p-10 lg:p-20 text-gray-800 relative md:flex items-center text-center md:text-left">
        <div className="w-full md:w-1/2">
          <div className="mb-10 md:mb-20 text-gray-600 font-light">
            <h1 className="font-black uppercase text-3xl lg:text-5xl text-yellow-500 mb-10">
              You seem to be lost!
            </h1>
            <p>The page you're looking for isn't available.</p>
            <p>Try searching again or use the Go Back button below.</p>
          </div>
          <div className="mb-20 md:mb-0">
            <button className="text-lg font-light outline-none focus:outline-none transform transition-all hover:scale-110 text-yellow-500 hover:text-yellow-600">
              <i className="mdi mdi-arrow-left mr-2"></i>
              <Link to="/"> Go Back </Link>
            </button>
          </div>
        </div>
        <div className="w-full md:w-1/2 text-center">
          <img src="/assets/404.svg" alt="" />
          <a
            className="font-light text-xs text-gray-200"
            target="_blank"
            rel="noreferrer"
            href="https://www.freepik.com/vectors/business"
          >
            Business vector created by pikisuperstar - www.freepik.com
          </a>
        </div>
      </div>
    </div>
  </Layout>
);

export default notFound;
